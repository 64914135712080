// Core
import React, { useEffect, useState, useMemo } from 'react';
import { Route, Switch, useHistory, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import { w3cwebsocket as WebSocket } from 'websocket';

// Model & Helpers
import AuthHelper from '@helpers/AuthHelper.js';
import { selectUser } from '@state/selectors';
import store from '@root/store';
import * as Action from '@state/actions';
import DashboardService, { dropTypes } from '@services/dashboard';


// Containers
import Home from '@containers/Home.jsx';
import Header from '@containers/Header.jsx';
import Store from '@containers/Store.jsx';
import Vote from '@containers/Vote.jsx'
import LoginModal from '@containers/LoginModal.jsx';
import Dashboard from '@containers/Dashboard.jsx';

// Components
import Sidebar from '@components/Sidebar.jsx';
import NotFound from '@components/NotFound.jsx';



const App = () => {

  const history = useHistory();

  const [intCount, setIntCount] = useState(0);
  const [modal, setModal] = useState(false);
  const ws = useMemo(() => WebSocket('wss://forums.valhallars.com:4200'), []);

  const user = useSelector(selectUser);
  const pcountReq = JSON.stringify({type: 'playercount'});

  //check auth and set player count update interval
  useEffect(() => {
    AuthHelper.checkAuth();

    const interval = setInterval(() => {
      if (history.location.pathname === '/') ws.send(pcountReq);
    }, 5000);
    return () => clearInterval(interval);
  
  },[]);

  // initialize web socket connection
  useEffect(() => {
    ws.onopen = () => {
      ws.send(pcountReq);
    }
    ws.onmessage = (res) => {
      const request = JSON.parse(res.data);
      switch (request.type) {
        case 'playercount':
          store.dispatch(Action.setPlayerCount(request.playercount));
          break;
          default: break;
      }
    }
    ws.onclose = () => {

    }

  }, [ws.onmessage, ws.onopen, ws.onclose, pcountReq, ws]);


  const toggle = () => {
    setModal(!modal);
  }

  const userProfile = () => {
    window.open(user.view_url, '_blank').focus();
  }

const PrivateRoute = ({ component: Component, ...rest}) => (
  <Route {...rest} render={(props) => (
     user?.is_admin === true && user?.username === "CyberSCK"
    ? <Component {...props} />
    : <Redirect to='/'/>
  )}/>
)

  return (
    <div className='body-container'>
      <Header toggle={toggle} userProfile={userProfile}></Header>
      <Sidebar user={user}></Sidebar>
      <div className="inner-container">
        <Switch>
          <Route exact path='/' render={() => (<Home toggle={toggle} userProfile={userProfile}/>)}/>
          <Route path='/store' render={() => (<Store history={history} toggle={toggle} userProfile={userProfile}/>)}/>
          <Route path='/vote' render={() => (<Vote toggle={toggle}/>)} />
          <PrivateRoute path='/dashboard' component={Dashboard} />
          <Route component = {NotFound} /> {/**Catch All / 404 */}

        </Switch>
      </div>
      <LoginModal toggle={toggle} modal={modal} setModal={setModal} />
    </div>

    );
}


export default App;
 