// Core
import React, {useState, useMemo, useEffect} from 'react';
import { useSelector } from 'react-redux';


// Components
import Bonds from '@components/store-items/Bonds.jsx';
import Item from '@components/Item.jsx';
import GamblePack from '@components/store-items/GamblePack.jsx';
import PvmPack from '@components/store-items/PvmPack';
import SkillerPack from '@components/store-items/SkillerPack';
import VIP from '@components/store-items/VIP';
import Limitted from '@components/store-items/Limitted.jsx';

// Model & Helpers
import { selectStatus } from '@state/selectors';
import { selectUser } from '@state/selectors';
import StoreService from '@services/store';
import { InputGroup, Input, InputGroupText, Spinner, Button } from 'reactstrap';
import RankBooster from '../components/store-items/RankBooster';
import Investor from '../components/store-items/Investor';





const Store = ({history, toggle, userProfile}) => {

    const items = require('@helpers/items.json');
    //  MOVE TO CHECKOUT PAGE
    // script source "https://www.paypal.com/sdk/js?client-id=sb"
    // const [paidFor, setPaidFor] = useState(false);
    // const [loaded, setLoaded] = useState(false);


    const isAuth = useSelector(selectStatus);
    const user = useSelector(selectUser);

    const openItem = history.location.search.replace('?open=','');
    const voucherCode = history.location.search.includes('open') ? null : history.location.search.replace('?voucher=', '');

    const [activeVoucher, setActiveVoucher] = useState(voucherCode || '');
    const [loadingVoucherStatus, setLoadingVoucherStatus] = useState(false);
    const [voucherStatus, setVoucherStatus] = useState(null);
    const [currentTimeout, setCurrentTimeout] = useState(null);
    const [statusColor, setStatusColor] = useState("red");
    const [forceUpdate, setForceUpdate] = useState(false);



    const isValidVoucher = useMemo(() => {
        setLoadingVoucherStatus(true);
        setVoucherStatus(null);
        if (currentTimeout !== null) clearTimeout(currentTimeout);
        if (activeVoucher.replace(/ /, '').length > 0) {
        const recursive = (delay) => {
            const timeout = setTimeout(async () => {
                await new Promise((resolve, reject) => {
                    StoreService.validateVoucher(activeVoucher, resolve, reject);
                }).then(status => {
                    setStatusColor("red");
                    setVoucherStatus(status);
                    setLoadingVoucherStatus(false);
                }).catch(err => {
                    if (err === 401) recursive(2000);
                })
            },delay);
            setCurrentTimeout(timeout);
        }    
        recursive(500);
    } else {
        setVoucherStatus(null);
        setCurrentTimeout(null);
        setActiveVoucher('');
        setLoadingVoucherStatus(false);
    }
    }, [activeVoucher, forceUpdate]);

    // add item components here
    const Paypal = (comp) => {
        switch (comp) {
            case 'Bonds':
                return (<Bonds username={user?.username}/>);
            case 'Gamble':
                return (<GamblePack username={user?.username}/>);
            case 'Pvm':
                return (<PvmPack username={user?.username}/>);
            case 'Skiller':
                return (<SkillerPack username={user?.username}/>);
            case 'VIP':
                return (<VIP username={user?.username}/>);
            case 'Limitted':
                return (<Limitted username={user?.username}/>);
            case 'RankBooster':
                return (<RankBooster username={user?.username}/>);
            case 'Investor':
                return (<Investor username={user?.username}/>);

            default:
                return (<></>);
        }
    }


    const signup = () => {
        window.open('https://forums.valhallars.com/register', '_blank').focus();
    }

    const logout = () => {
        StoreService.logout();
    }

    const handleVoucher = (e) => {
        setActiveVoucher(e.target.value);
    }

    const redeem = async () => {
        await new Promise((resolve, reject) => {
            StoreService.redeemVoucher(activeVoucher, resolve, reject);
        }).then((status) => {
            setVoucherStatus(status);
            setStatusColor("green");
            let v = document.getElementById('voucher');
            v.value = '';
            setTimeout(() => {
                setVoucherStatus(null);
                setLoadingVoucherStatus(false);
                setActiveVoucher('');
            }, 5000)
        }).catch((err) => {
            setVoucherStatus(err);
            setStatusColor("red");
        })
    }

    return (
        <div className='store'>
            <div className='user-login'> 
            <div className='background-box'>
            {isAuth ? 
            <><p className="welcome">Hello, <span className='username' onClick={userProfile}>{user?.username}</span>. <span className="logout" onClick={logout}>Logout</span></p> <p>You're all set to start enhancing your experience! </p> 
                <InputGroup>
                    <InputGroupText className="prepend">Gift Voucher:</InputGroupText>
                    <Input id='voucher' defaultValue={voucherCode} onChange={handleVoucher}/>
                </InputGroup>
                {loadingVoucherStatus && <Spinner id="spin" color="info"></Spinner>}
                {voucherStatus === 'Valid' && <Button onClick={() => redeem()} color="success">Redeem</Button>}
                {voucherStatus !== null && voucherStatus !== 'Valid' && <p style={{color: statusColor}}>{voucherStatus}!</p>}
            </>
            :
            <p><span className='login' onClick={() => {toggle()}}>Login</span> to make donations and redeem vouchers! Haven't signed up yet? <span className='create' onClick={signup}>Create an account!</span></p>
            }
            </div>
            </div>
            <br/>
            {items.map(item => (
                <Item item={item} key={item.name} autoOpen={openItem.toLowerCase() === item.component.toLowerCase()} Paypal={Paypal(item.component)}></Item>
            ))}



        </div>
    )
}

export default Store;
