import React from 'react'


import { useSelector } from 'react-redux';
import { selectStatus } from '@state/selectors';
import { selectUser } from '@state/selectors';



const cart = require('@resources/cart.png');
const dcart = require('@resources/cart-disabled.png');
const img = require('@resources/limitted.png');

const packages = [
    [
        {qty: '1X •', item: '$50 Bond', description: 'Redeem this to add towards your donator rank and spend the credits in the donator shop!'},
        {qty: '1X •', item: '1 Month VIP Voucher', description: ''},
        {qty: '1X •', item: 'Bandos Crossbow', description: 'Limitted edition item, will never be released again.'},
        {qty: '1000X •', item: 'Onyx bolts (e)', description: ''},
        {qty: '1000X •', item: 'Diamond bolts (e)', description: ''},
        {qty: '1000X •', item: 'Ruby bolts (e)', description: ''},
    ]
];

const Limitted = () => {

    const isAuth = useSelector(selectStatus);
    const user = useSelector(selectUser);

    const saleId = '';

    return (
        <div className="store-item-paypal">

            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="LAAT8J68ZLYPQ" />
                <input type="hidden" name="on1" value={user?.username} />
                <input type="hidden" name="on2" value={saleId} />
                <input className={isAuth ? '' : 'd-cursor'} disabled={!isAuth} type="image" src={isAuth ? cart : dcart} border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>

            <div>
            <img draggable="false" alt="VIP subscription logo" className='item-img-wide' src={img} />
            {packages[0].map((item, index) => (
                <p key={index}><span className='qty' name="watch" value={item.qty}>{item.qty}</span> <span className='item-name' name="watch" value={item.item}>{item.item}</span> <span className='desc'>{item.description}</span></p>
            ))}

            </div>
        </div>
    )
}

export default Limitted;





