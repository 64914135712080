import {AxiosAuth} from '@helpers/AxiosAuth';
import Axios from 'axios';
import DashboardController from '@controllers/DashboardController';
import AlertsController from '@controllers/AlertsController';

class DashboardService {
    baseUrl = 'https://forums.valhallars.com:4200';

    getDrops(type, resolve, reject) {
        AxiosAuth().get(`${this.baseUrl}/dashboard/getdrops/${type}`)
        .then(response => {
            switch (type) {
                case dropTypes.NPC:
                    DashboardController.setNpcDrops(response.data.dropList);
                    break;
                case dropTypes.ITEM:
                    DashboardController.setItemDrops(response.data.dropList);
                    break;
                case dropTypes.OBJECT:
                    DashboardController.setObjectDrops(response.data.dropList);
                    break;
            }
            resolve();
        }).catch(err => {
            console.log(err);
            reject();
        });
    }

    saveDrop(type, data) {
        AxiosAuth().post(`${this.baseUrl}/dashboard/savedrop/${type}`, data)
            .then(response => {
                // AlertsController.addAlert({...AlertsController.newAlert, text: 'Saved Drop', alertType: 'success'});
            }).catch(err => {
                AlertsController.addAlert({...AlertsController.newAlert, text: `${err}`, alertType: 'error'});
            });
            
    }

    saveShop(name, shop) {
        AxiosAuth().post(`${this.baseUrl}/dashboard/saveShop`, {name, shop})
    }

    saveToProd(path) {
        AxiosAuth().post(`${this.baseUrl}/dashboard/saveToProd/${path}`)
            .then(response => {
                // AlertsController.addAlert({...AlertsController.newAlert, text: 'Saved Data to Prod', alertType: 'success'});
            }).catch(err => {
                AlertsController.addAlert({...AlertsController.newAlert, text: `${err}`, alertType: 'error'});
            });
    }

    getItemList(resolve, reject) {
        AxiosAuth().get(`${this.baseUrl}/dashboard/itemList`)
            .then(response => {
                DashboardController.setItemList(response.data);
                resolve();
            }).catch(err => {
                console.log(err);
                reject();
            });
    }

    getNpcList(resolve, reject) {
        AxiosAuth().get(`${this.baseUrl}/dashboard/npcList`)
            .then(response => {
                DashboardController.setNpcList(response.data);
                resolve();
            }).catch(err => {
                console.log(err);
                reject();
            });
    }

    getObjectList(resolve, reject) {
        AxiosAuth().get(`${this.baseUrl}/dashboard/objectList`)
            .then(response => {
                DashboardController.setObjectList(response.data);
                resolve();
            }).catch(err => {
                console.log(err);
                reject();
            });
    }

    getShopList(resolve, reject) {
        AxiosAuth().get(`${this.baseUrl}/dashboard/shopList`)
            .then(response => {
                DashboardController.setShops(response.data.data);
                const shopList = Object.keys(response.data.data).map((key) => {return {name: Object.keys(response.data.data[key])[0].replace(/_/g, ' ')}})
                DashboardController.setShopList(shopList);
                resolve();
            }).catch(err => {
                console.log(err);
                reject();
            });
    }

    getPlayers(resolve, reject) {
        AxiosAuth().get(`${this.baseUrl}/dashboard/players/live`)
            .then(response => {
                response.data.unshift({username: 'All', user_id: 0});
                DashboardController.setPlayers(response.data);
                resolve();
            }).catch(err => {
                console.log(err);
                reject();
            });
    }

    getRewards(resolve, reject) {
        AxiosAuth().get(`${this.baseUrl}/dashboard/rewards`)
            .then(response => {
                DashboardController.setRewards(response.data);
                resolve();
            }).catch(err => {
                console.log(err);
                reject();
            })
    }

    addReward(resolve, reject) {
        AxiosAuth().post(`${this.baseUrl}/dashboard/addReward`)
            .then(() => {
                resolve();
            }).catch(err => {
                console.log(err);
                reject();
            })
    }

    saveReward(reward) {
        AxiosAuth().post(`${this.baseUrl}/dashboard/updateReward`, {...reward})
            .catch(err => {
                console.log(err);
            });
    }

}

export const dropTypes = {
    NPC: 'npcs',
    ITEM: 'items',
    OBJECT: 'objects'
}

export default new DashboardService();