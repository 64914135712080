// Core
import React from 'react';
import { useSelector } from 'react-redux';

// Model and Helpers
import { selectPlayerCount } from '@state/selectors';


export default function Home({toggle, userProfile}) {

  const playerCount = useSelector(selectPlayerCount) | 'Loading...';


  const navTo = (e) => {
    switch(e.target.id) {
      case 'bounty':
        window.open('https://forums.valhallars.com/threads/bug-bounty-program-read-before-posting.3/', '_blank').focus();
        break;
        case 'store':
          window.open('https://valhallars.com/store');
          break;
        case 'download':
          window.open('https://forums.valhallars.com/client/Valhalla-Launcher.jar', '_blank');
          break;
        case 'register':
          window.open('https://forums.valhallars.com/register', '_blank').focus();
          break
          default: break;
    }
  }


    return (
    <div className='home'>
        <div className='jumbotron'>
            <p className='top-row'>
              <span className='players'>Players Online: <span className='p-count'>{typeof(playerCount) === 'number' ? playerCount : 'Loading...'}</span></span>
            </p>

            <p className= 'welcome'>To create an account in game, <span className='emphasis' id='register' onClick={navTo}>Register</span> on the forums. <span className='emphasis' id='download' onClick={navTo}>Download</span> the client. </p>
        </div>
        </div>
    )

}