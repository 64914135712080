import React, { useState, useEffect} from 'react'
import { Card, CardImg, CardText, CardBody, CardSubtitle, Modal, ModalHeader, ModalBody } from 'reactstrap';

const Item = ({item, Paypal, autoOpen}) => {

    const [modal, setModal] = useState(false);
    const [openedInSession, setOpenedInSession] = useState(false);

    const toggle = () => {
        setModal(!modal);
    }

    useEffect(() => {
        if (autoOpen && !modal && !openedInSession) {
            toggle();
            setOpenedInSession(true);
        }
    },[]);
    return (
        <div className='item no-select'>
            <Card onClick={toggle}>
            <p className='title'>{item.name}</p>
                <CardImg top width="100%" src={require(`@resources/${item.image}`)} alt={item.image} />
                <CardBody>

                    <CardSubtitle tag="h5" className='mb-2 text-muted'>{item.price}</CardSubtitle>
                    <CardText>{item.description}</CardText>
                </CardBody>
            </Card>


        
        <Modal isOpen={modal} toggle={toggle} className=''>
            <ModalHeader toggle={toggle}>{item.title}</ModalHeader>
            <ModalBody>
                {Paypal}
            </ModalBody>
        </Modal>
        </div>
    )
}

export default Item
