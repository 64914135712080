import React, { useState } from 'react'
import  * as jwt  from 'jsonwebtoken';


import { useSelector } from 'react-redux';
import { selectStatus } from '@state/selectors';
import { selectUser } from '@state/selectors';



const cart = require('@resources/cart.png');
const dcart = require('@resources/cart-disabled.png');
const img = require('@resources/investor.png');


const Investor = () => {

    const isAuth = useSelector(selectStatus);
    const user = useSelector(selectUser);

    const saleId = '';

    const description = 'Get 70% of the value of your package up front, and an additional 5% every month, FOREVER. The longer you play, the more you receive. *Limited Quantity Available*'
    const packages = [
        
            {qty: '1X •', item: 'Supporter:', description}
        ,
        
            {qty: '1X •', item: 'Advocate:', description}
        ,
        
            {qty: '1X •', item: 'Ally:', description}
        ,
        
            {qty: '1X •', item: 'Leader:', description}
        
    ];

    const [pack, setPack] = useState(packages[0]);
    const [watchList] = useState(document.getElementsByName('watch'));

    const config = {  attributes: true, childList: true, characterData: true }
    
    const callback = function(mutationsList, observer) {
        for (let m of mutationsList) {
            if (m.attributeName === 'value') {
                m.target.classList.add('flash');
                setTimeout(() => {
                    m.target.classList.remove('flash');
                }, 3000);

            }
        }
    }
    const observer = new MutationObserver(callback);



    for (let el of watchList) {
        observer.observe(el, config);
    }


    return (
        <div className="store-item-paypal">
            <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="5VDUBSLVFNEAS" />
                <input type="hidden" name="on1" value={user?.username} />
                <input type="hidden" name="on2" value={saleId} />
                <input type="hidden" name="quantity" value={1}/>
                <table>
                    <tr><td>
                        <input type="hidden" name="on0" value="Tier"/>
                        Tier
                    </td></tr>
                    <tr><td>
                        <select name="os0" onChange={(e) => setPack(packages[e.target.selectedIndex])}>
                        <option value="Supporter">Supporter • $100.00 USD</option>
                        <option value="Advocate">Advocate • $250.00 USD</option>
                        <option value="Ally">Ally • $500.00 USD</option>
                        <option value="Leader">Leader • $1000.00 USD</option>
                        </select>
                    </td>
                    </tr>
                </table>
                <input type="hidden" name="currency_code" value="USD" />
                <br/>      
                <input className={isAuth ? '' : 'd-cursor'} disabled={!isAuth} type="image" src={isAuth ? cart : dcart} border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>
            <div>
            <img draggable="false" alt="VIP subscription logo" className='item-img-wide2' src={img} />
                <p><span className='qty' name="watch" value={pack.qty}>{pack.qty}</span> <span className='item-name' name="watch" value={pack.item}>{pack.item}</span> <span className='desc'>{pack.description}</span></p>
            </div>
        </div>
    )
}

export default Investor;





