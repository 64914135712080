import Axios from 'axios';
import LoginController from '@controllers/loginController';

// Use this for all API calls which require user authentication
export const AxiosAuth = () => {
    const token = window.localStorage.getItem('VRSUser') || '';

    const authReq = Axios.create({
        headers:{
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Headers': '*',
            'Authorization': `${token}`
        },
    });
    authReq.interceptors.response.use(
        config => {
            return config;
        },
        error => {
            if (error.request.status === 401) handleUnauthorized();
            return Promise.reject(error);
        }
    );
    return authReq;
}


function handleUnauthorized() {
    LoginController.setUser(null);
    LoginController.setStatus(false);
    window.localStorage.removeItem('VRSUser');
}