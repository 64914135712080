import React from 'react';
import {createRoot} from 'react-dom/client';
import {BrowserRouter as Router} from 'react-router-dom';
import App from './App';
import { Provider } from 'react-redux';
import store from '@root/store';
import { AxiosAuth } from '@helpers/AxiosAuth';

const root = createRoot(document.getElementById('root'));
root.render(<Provider store={store}><Router><App className='App'/></Router></Provider>);
