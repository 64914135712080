import jwt from 'jsonwebtoken';

export const selectUser = state => jwt.decode(state.user);

export const selectStatus = state => state.authStatus;

export const selectPlayerCount = state => state.onlinePlayers;

export const selectNpcDrops = state => state.npcDrops;

export const selectItemDrops = state => state.itemDrops;

export const selectObjectDrops = state => state.objectDrops;

export const selectItemList = state => state.itemList;

export const selectNpcList = state => state.npcList;

export const selectObjectList = state => state.objectList;

export const selectAlerts = state => state.alerts;

export const selectShopList = state => state.shopList;

export const selectShops = state => state.shops;

export const selectPlayers = state => state.players;

export const selectRewards = state => state.rewards;