import React, { useState } from 'react'

import { useSelector } from 'react-redux';
import { selectStatus } from '@state/selectors';
import { selectUser } from '@state/selectors';


const cart = require('@resources/cart.png');
const dcart = require('@resources/cart-disabled.png');


const saleId = '';

const bondText = 'Redeem this to add towards your donator rank and spend the credits in the donator shop!';

const images = [
    require('@resources/skiller-nature.png'),
    require('@resources/skiller-industrial.png'),
    require('@resources/skiller-master.png')
]
const packages = [
    [
        {qty: '2X •', item: '$10 Bond:', description: bondText},
        {qty: '250X •', item: 'Raw Shark', description: ''},
        {qty: '200X •', item: 'Raw Swordfish', description: ''},
        {qty: '250X •', item: 'Magic Logs', description: ''},
        {qty: '200X •', item: 'Redwood Logs', description: ''},
        {qty: '100X •', item: 'Ranarr Potion (unf) / Snape Grass', description: ''},
        {qty: '100X •', item: 'Snapdragon Potion (unf) / Red Spiders Eggs', description: ''},
        {qty: '100X •', item: 'Toadflax Potion (unf) / Crushed birdnests', description: ''},
        {qty: '10X •', item: 'Magic Seed', description: ''},
        {qty: '20X •', item: 'Yew Seeds', description: ''},

        
    ],
    [
        {qty: '2X •', item: '$10 Bond:', description: bondText},
        {qty: '200X •', item: 'Rune Ore', description: ''},
        {qty: '1600X •', item: 'Coal', description: ''},
        {qty: '250X •', item: 'Mahogany Plank', description: ''},
        {qty: '400X •', item: 'Teak Plank', description: ''},
        {qty: '250X •', item: 'Dragon Dart Tip', description: ''},
        {qty: '250X •', item: 'Rune Dart Tip', description: ''},
        {qty: '500X •', item: 'Adamant Dart Tip', description: ''},
        {qty: '1000X •', item: 'Feathers', description: ''},
        {qty: '200X •', item: 'Black Dragonhide', description: ''},
        {qty: '100X •', item: 'Battlestaves / Air Orbs', description: ''},

    ],
    [
        {qty: '4X •', item: '$10 Bond:', description: bondText},
        {qty: '250X •', item: 'Raw Shark', description: ''},
        {qty: '200X •', item: 'Raw Swordfish', description: ''},
        {qty: '250X •', item: 'Magic Logs', description: ''},
        {qty: '200X •', item: 'Redwood Logs', description: ''},
        {qty: '100X •', item: 'Ranarr Potion (unf) / Snape Grass', description: ''},
        {qty: '100X •', item: 'Snapdragon Potion (unf) / Red Spiders Eggs', description: ''},
        {qty: '100X •', item: 'Toadflax Potion (unf) / Crushed birdnests', description: ''},
        {qty: '10X •', item: 'Magic Seed', description: ''},
        {qty: '20X •', item: 'Yew Seeds', description: ''},
        {qty: '200X •', item: 'Rune Ore', description: ''},
        {qty: '1600X •', item: 'Coal', description: ''},
        {qty: '250X •', item: 'Mahogany Plank', description: ''},
        {qty: '400X •', item: 'Teak Plank', description: ''},
        {qty: '250X •', item: 'Dragon Dart Tip', description: ''},
        {qty: '250X •', item: 'Rune Dart Tip', description: ''},
        {qty: '500X •', item: 'Adamant Dart Tip', description: ''},
        {qty: '1000X •', item: 'Feathers', description: ''},
        {qty: '200X •', item: 'Black Dragonhide', description: ''},
        {qty: '100X •', item: 'Battlestaves / Air Orbs', description: ''},
    ]
];



const SkillerPack = () => {


    const isAuth = useSelector(selectStatus);
    const user = useSelector(selectUser);

    const [pack, setPack] = useState(packages[0]);
    const [img, setImg] = useState(images[0]);
    const [watchList] = useState(document.getElementsByName('watch'));

    const updatePackage = (e) => {
        setImg(images[e.target.selectedIndex]);
        setPack(packages[e.target.selectedIndex]);
    }


    const config = {  attributes: true, childList: true, characterData: true }
    
    const callback = function(mutationsList, observer) {
        for (let m of mutationsList) {
            if (m.attributeName === 'value') {
                m.target.classList.add('flash');
                setTimeout(() => {
                    m.target.classList.remove('flash');
                }, 3000);

            }
        }
    }
    const observer = new MutationObserver(callback);

    for (let el of watchList) {
        observer.observe(el, config);
    }

    return (
        <div className="store-item-paypal">
            
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="LF8Q6GJM89T56" />
                <input type="hidden" name="on1" value={user?.username} />
                <input type="hidden" name="on2" value={saleId} />
                <table>
                    <thead>
                        <tr><td><input type="hidden" name="on0" value="Package" />Package</td></tr>
                    </thead>
                    <tbody>
                        <tr><td>
                        <select name="os0" onChange={updatePackage}>
                            <option value="Nature">Nature $30.00 USD</option>
                            <option value="Industrial">Industrial $30.00 USD</option>
                            <option value="Skiller Master">Skiller Master $50.00 USD</option>
                        </select>  
                        </td></tr>
                    </tbody>
                </table>
                <input type="hidden" name="currency_code" value="USD" />
                <br/>
                <input className={isAuth ? '' : 'd-cursor'} disabled={!isAuth} type="image" src={isAuth ? cart : dcart} border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
                <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>


            <div>
            <img draggable="false" alt="items included in the slected package" className='item-img' src={img} />
            {pack.map((item, index) => (
                <p key={index}><span className='qty' name="watch" value={item.qty}>{item.qty}</span> <span className='item-name' name="watch" value={item.item}>{item.item}</span> <span className='desc'>{item.description}</span></p>
            ))}

            </div>
        </div>
    )
}

export default SkillerPack;
