import store from '@root/store';
import * as Action from '@state/actions';

import Axios from 'axios';


class Utils {

    getPlayerCount() {
        Axios.get('https://forums.valhallars.com:4200/util/players')
        .then(response => {
            store.dispatch(Action.setPlayerCount(response.data[0].players_online));
        }).catch(err => console.log(err));
    }

    refreshVoteTimer = () => {
        const token = window.localStorage.getItem('VRSUser');
        if (token == null) return;
        Axios.post('https://forums.valhallars.com:4200/util/getvotetimer', {token: token})
        .then(response => {
            window.localStorage.setItem('VRSUser', response.data);
            store.dispatch(Action.setUser(response.data));
        }).catch(err => console.log(err));
    }

}

export default new Utils();