import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';


const NotFound = () => {

    const history = useHistory();

    let [timer, setTimer] = useState(6);

    function timerActive() {
        setTimer(--timer);
    }
    function timerExpired() {
        history.push('/');
        history.go();
    }
    
    useEffect(() => {
        let timeOut = setInterval(() => {timer > 0 ? timerActive() : timerExpired()}, 1000);
        return function cleanup() {
            window.clearTimeout(timeOut);
        }
    });

    return (
        <div className='not-found'>
            <div className='background-box'>
                <h2>Looks like you navigated to the <span className='wrong'>wrong page</span>, don't worry.<br/><br/> Redirecting you in <span className='redirect'>{timer}</span></h2>
            </div>
        </div>
    )
}

export default NotFound;