import store from '@root/store';
import * as Action from '@state/actions';


class DashboardController {
    
    setNpcDrops(drops) {
        store.dispatch(Action.setNpcDrops(drops));
    }

    setItemDrops(drops) {
        store.dispatch(Action.setItemDrops(drops));
    }

    setObjectDrops(drops) {
        store.dispatch(Action.setObjectDrops(drops));
    }

    setItemList(list) {
        store.dispatch(Action.setItemList(list));
    }

    setNpcList(list) {
        store.dispatch(Action.setNpcList(list));
    }

    setObjectList(list) {
        store.dispatch(Action.setObjectList(list));
    }

    setShopList(list) {
        store.dispatch(Action.setShopList(list));
    }

    setShops(shops) {
        store.dispatch(Action.setShops(shops));
    }

    setPlayers(players) {
        store.dispatch(Action.setPlayers(players));
    }

    setRewards(rewards) {
        store.dispatch(Action.setRewards(rewards));
    }

}

export default new DashboardController();