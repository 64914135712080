// Core
import React, { useState, useMemo, useEffect, memo } from 'react';
import { Table, Input, Button, FormGroup, Form, Label,
         InputGroup, InputGroupText, Badge, Dropdown, DropdownToggle, 
         DropdownMenu, DropdownItem, UncontrolledAccordion, AccordionHeader, AccordionBody } from 'reactstrap';
import { useSelector } from 'react-redux';
import {v4 as uuid} from 'uuid';

// Model & Helpers
import { selectItemDrops, selectNpcDrops, selectObjectDrops, selectNpcList, selectItemList, selectObjectList, selectShopList, selectShops, selectRewards, selectPlayers } from '@state/selectors';
import DashboardService, { dropTypes } from '@services/dashboard';

// Components
import DropItemIdSearch from '@components/DropItemIdSearch';
import DropSimModal from '@components/DropSimModal';
import DashboardController from '../../controllers/DashboardController';

const Tables = {
    GENERAL: 'GENERAL',
    DROPS: 'DROPS',
    SPAWNS: 'SPAWNS',
    SHOPS: 'SHOPS',
    VOUCHERS: 'VOUCHERS'
}

const Categories = {
    NONE: 'NONE',
    DROPS: 'DROPS',
    SHOPS: 'SHOPS',
    SPAWNS: 'SPAWNS',
    REWARDS: 'REWARDS'
}

const pressedKeys = {
    shift: false,
    ctrl: false    
}

window.onkeyup = (e) => {
    pressedKeys.shift = !e.key === 'Shift';
    pressedKeys.ctrl = !e.key === 'Control';
}
window.onkeydown = (e) => {
    pressedKeys.shift = e.key === 'Shift';
    pressedKeys.ctrl = e.key === 'Control';
}

const Dashboard = () => {

    const npcDrops = useSelector(selectNpcDrops);
    const itemDrops = useSelector(selectItemDrops);
    const objectDrops = useSelector(selectObjectDrops);
    const npcList = useSelector(selectNpcList);
    const itemList = useSelector(selectItemList);
    const objectList = useSelector(selectObjectList); //@TODO add object selector, query and endpoint
    const shopList = useSelector(selectShopList);
    const shops = useSelector(selectShops);
    const rewards = useSelector(selectRewards);
    const players = useSelector(selectPlayers);

    const [headers, setHeaders] = useState([]);
    const [page, setPage] = useState(1);
    const [currentData, setCurrentData] = useState([{name: 'Loading...'}]);

    const [activeFilter, setActiveFilter] = useState(-1);
    const [search, setSearch] = useState('');

    const [selectedTable, setSelectedTable] = useState(Tables.GENERAL);
    const [selectedCategory, setSelectedCategory] = useState(Categories.NONE);
    const [selectedEntity, setSelectedEntity] = useState('');

    // manage the current data set selected
    const [activeDrop, setActiveDrop] = useState(null);
    const [activeShop, setActiveShop] = useState(null);
    const [activeReward, setActiveReward] = useState(null);
    const [entityIdSearch, setEntityIdSearch] = useState('');
    const [playerIdSearch, setPlayerIdSearch] = useState('');
    const [itemIdSearch, setItemIdSearch] = useState(new Array(25).fill(new Array(125).fill('')));


    const [dropSimOpen, setDropSimOpen] = useState(false);
    const [loading, setLoading] = useState(!(npcDrops !== null && itemDrops !== null && objectDrops !== null && npcList !== null && itemList !== null && objectList !== null && shopList !== null && players !== null && rewards !== null));


    const [refresh, setRefresh] = useState(false);


    useEffect(() => {
        if (refresh) setRefresh(false);
    }, [refresh])
    //get data
    useEffect(() => {
        const loadData = async () => {
            setLoading(true);
            await Promise.all([
                new Promise((resolve, reject) => DashboardService.getDrops(dropTypes.NPC, resolve, reject)),
                new Promise((resolve, reject) => DashboardService.getDrops(dropTypes.ITEM, resolve, reject)),
                new Promise((resolve, reject) => DashboardService.getDrops(dropTypes.OBJECT, resolve, reject)),
                new Promise((resolve, reject) => DashboardService.getNpcList(resolve, reject)),
                new Promise((resolve, reject) => DashboardService.getItemList(resolve, reject)),
                new Promise((resolve, reject) => DashboardService.getObjectList(resolve, reject)),
                new Promise((resolve, reject) => DashboardService.getShopList(resolve, reject)),
                new Promise((resolve, reject) => DashboardService.getPlayers(resolve, reject)),
                new Promise((resolve, reject) => DashboardService.getRewards(resolve, reject))
            ])
            .catch((err) => {console.log('DATA ERROR')});
        }
        if (npcDrops === null && itemDrops === null && objectDrops === null && npcList === null && itemList === null && objectList === null && shopList === null && players === null && rewards === null) 
            loadData();
    }, []);


    // handle input into the filter
    const handleFilter = (e) => {
        setSearch(e.target.value);
        setCurrentData([...currentData]);
    }

    // return an empty drops object
    const newDrop = (id, type) => {
        const dataArr = type === 'npcs' ? Object.values(npcList) : type === 'items' ? Object.values(itemList) : Object.values(objectList);
        const dataMap = new Map(dataArr.map(d => [d.id, d.name]))
        return {
            ids: [ id ],
            guaranteed: [],
            tables: [],
            name: `${dataMap.get(id).replaceAll(' ', '_')}.json`
        }
    }

    // return the drops for an entity
    const getDropsFor = (type, obj) => {
        let d;
        switch (type) {
            case 'npcs':
                d = npcDrops.filter(drop => drop?.ids?.includes(obj.id));
                if (d?.length > 1) console.log(d[0].ids[0] + "has duplicate entry"); 
                return d.length === 0 ? newDrop(obj.id, type) : d[0];
            case 'items':
                d = itemDrops?.filter(drop => drop?.ids?.includes(obj.id));
                if (d?.length > 1) console.log(d[0].ids[0] + "has duplicate entry"); 
                return d.length === 0 ? newDrop(obj.id, type) : d[0];
            case 'objects':
                d = objectDrops?.filter(drop => drop?.ids?.includes(obj.id));
                if (d?.length > 1) console.log(d[0].ids[0] + "has duplicae entry");
                return d.length === 0 ? newDrop(obj.id, type) : d[0];
                break;
        }
    }

    // select an item on the general table
    const selectGeneralRow = (e, data) => {
        switch (selectedCategory) {
            case Categories.DROPS:
                setSelectedTable(Tables.DROPS);
                setActiveDrop(data);
                break;
            case Categories.SHOPS:
                setSelectedTable(Tables.SHOPS);
                setActiveShop(data);
                break;
            case Categories.REWARDS: 
                setSelectedTable(Tables.VOUCHERS);
                setActiveReward({...data, items: data.items ? data.items : [], ids: []}); 
            case Categories.NONE:
            default:break;
        }
    }

    // return to main table from sub table
    const backToGeneral = () => {
        switch (selectedCategory) {
            case Categories.DROPS:
                setHeaders(['name', 'id']);
                break;
            case Categories.SHOPS:
                setHeaders(['name']);
                break;
            case Categories.REWARDS:
                setHeaders(['name', 'id'])
            case Categories.NONE:
                default:break;
        }
        setSelectedTable(Tables.GENERAL);

        // reset search index
        setItemIdSearch(new Array(25).fill(new Array(125).fill('')));
    }

    // select which drop type should be searched for on the general table
    const selectDropType = (e) => {
        setSelectedCategory(Categories.DROPS);
        switch (e.target.value) {
            case 'npcs':
                setCurrentData(Object.values(npcList).filter(npc => npc.combat === true).map(d => { return {...d, data: getDropsFor(e.target.value, d)}}));
                break;
            case 'items':
                setCurrentData(Object.values(itemList).filter(item => item.canOpen).map(d => { return {...d, data: getDropsFor(e.target.value, d)}}));
                break;
            case 'objects':
                setCurrentData(Object.values(objectList).map(d => { return {...d, data: getDropsFor(e.target.value, d)}}));
                break;
        }
        setSearch('');
        setSelectedEntity(e.target.value);
        setHeaders(['name', 'id']);
    }

    // handle the drops form
    const handleDropForm = (e, index) => {
        //index[0] = table index, index[1] = item index
        const temp = {...activeDrop};
        const intVal = Math.max(Number.parseInt(e.target.value), 1);
        let qtyMultiplier = pressedKeys.ctrl ? 100 : pressedKeys.shift ? 10 : 1;

        switch (e.target.id) {
            case 'name':
                setActiveDrop({...temp, name: e.target.value});
                break;
            case 'remove-id':
                temp.ids.splice(index[0], 1);
                setActiveDrop(temp);
                break;
            case 'add-id':
                if (!isNaN(intVal)) {
                    temp.ids.push(Number.parseInt(e.target.value));
                    setActiveDrop(temp);
                }
                break;
            case `table-name${index[0]}`:
                temp.tables[index[0]].name = e.target.value;
                setActiveDrop(temp);    
                break;
            case `table-weight${index[0]}`:
                if (!isNaN(intVal)) {
                    temp.tables[index[0]].weight = intVal;
                    setActiveDrop(temp);
                }
                break;
            case `add-table`:
                temp.tables.push({name: 'New Table', weight: 1, items: []});
                setActiveDrop(temp);
                break;
            case `remove-table${index[0]}`:
                temp.tables.splice(index[0], 1);
                setActiveDrop(temp);
                break;
            case `add-item${index[0]}`:
                if (index[0] === -1) {
                    if (temp.guaranteed === undefined) temp.guaranteed = [];
                    qtyMultiplier = Math.min(qtyMultiplier, 100 - temp.guaranteed.length);
                    temp.guaranteed.push(...new Array(qtyMultiplier).fill({id: 0, min: 1, max: 1}));
                }
                else {
                    qtyMultiplier = Math.min(qtyMultiplier, 100 - temp.tables[index[0]].items.length);
                    temp.tables[index[0]].items.push(...new Array(qtyMultiplier).fill({id: 0, min: 1, max: 1, weight: 1}));
                }
                    setActiveDrop(temp);
                break;
            case `remove-item${index[0]}${index[1]}`:
                if (index[0] === -1) 
                    temp.guaranteed.splice(index[1], 1);
                else temp.tables[index[0]].items.splice(index[1], 1);
                setActiveDrop(temp);
                setRefresh(true);
                break;
            case `item-min${index[0]}${index[1]}`:
                if (!isNaN(intVal)) {
                    if (index[0] === -1) {
                        temp.guaranteed[index[1]].max = Math.max(intVal, temp.guaranteed[index[1]].max);
                        temp.guaranteed[index[1]].min = intVal;
                    } else {
                        temp.tables[index[0]].items[index[1]].max = Math.max(intVal, temp.tables[index[0]].items[index[1]].max);
                        temp.tables[index[0]].items[index[1]].min = intVal;
                    }
                    setActiveDrop(temp);
                }
                break;
            case `item-max${index[0]}${index[1]}`:
                if (!isNaN(intVal)) {
                    if (index[0] === -1) {
                        temp.guaranteed[index[1]].min = Math.min(intVal, temp.guaranteed[index[1]].min);
                        temp.guaranteed[index[1]].max = intVal;
                    } else {
                        temp.tables[index[0]].items[index[1]].min = Math.min(intVal, temp.tables[index[0]].items[index[1]].min);
                        temp.tables[index[0]].items[index[1]].max = intVal;
                    }
                    setActiveDrop(temp);
                }
                break;
            case `item-weight${index[0]}${index[1]}`:
                if (!isNaN(intVal)) {
                    temp.tables[index[0]].items[index[1]].weight = intVal;
                    setActiveDrop(temp);
                }
                break;

                default:break;
        }
    }

    const handleRewardForm = (e, index) => {
        const temp = {...activeReward};
        const intVal = Math.max(Number.parseInt(e.target.value) | 0, 0);
        switch (e.target.id) {
            case 'name':
                temp.name = e.target.value;
                break;
            case 'add-item':
                temp.items.push({id: 0, amount: 1, price: 1});
                break;
            case `remove-item${index}`:
                temp.items.splice(index, 1);
                break;
            case `item-amount${index}`:
                temp.items[index].amount = intVal;
                break;
            case `add-id`:
                temp.ids = [...temp.ids, intVal];
                break;
            case `remove-id`:
                temp.ids.splice(index[0], 1);
            default:break;
        }

        setActiveReward(temp);

    }

    const handleShopForm = (e, index) => {
        const temp = {...activeShop};
        const intVal = Math.max(Number.parseInt(e.target.value) | 0, 1);
        let qtyMultiplier = pressedKeys.ctrl ? 100 : pressedKeys.shift ? 10 : 1;
        switch (e.target.id) {
            case 'name':
                temp.name = e.target.value;
                break;
            case 'identifier':
                temp.identifier = e.target.value;
                break;
            case 'title':
                temp.title = e.target.value;
                break;
            case 'currency':
                temp.currency = e.target.value.toUpperCase();
                break;
            case 'ironman':
                temp.accessibleByIronMan = !temp.accessibleByIronMan;
                break;
            case 'sell':
                temp.canSellToStore = !temp.canSellToStore;
                break;
            case 'general':
                temp.generalStore = !temp.generalStore;
                break;
            case 'donator':
                temp.donationShop = !temp.donationShop;
                break;
            case 'fence':
                temp.fence = !temp.fence;
                break;
            case 'restocktick':
                temp.restockRules.restockTicks = intVal;
                break;
            case 'restockamount':
                temp.restockRules.restockPerTick = intVal;
                break;
            case 'add-item':
                qtyMultiplier = Math.min(qtyMultiplier, 100 - temp.defaultStock.length);
                temp.defaultStock.push(...new Array(qtyMultiplier).fill({id: 0, amount: 1, price: 1}));
                break;
            case `remove-item${index}`:
                temp.defaultStock.splice(index, 1);
                break;
            case `item-amount${index}`:
                temp.defaultStock[index].amount = intVal;
                break;
            case `item-price${index}`:
                temp.defaultStock[index].price = intVal;
                break;
            default:
                break;
        }

        setActiveShop(temp);
    }



    // on select of an npc search result in drops form
    const selectNpcIdSearch = (e, result) => {
        document.getElementById('npc-id-search').value = result;
        document.getElementById('add-id').value = e.target.value;
    }

    const selectPlayerIdSearch = (e, result) => {
        document.getElementById('player-id-search').value = result;
        document.getElementById('add-id').value = e.target.value;
    }

    // handle input typed into the dropdown search for npc id's in drops form
    const handleDropNpcIdSearch = (e) => {
        setEntityIdSearch(e.target.value);
    }

    const handlePlayerIdSearch = (e) => {
        setPlayerIdSearch(e.target.value);
    }

    const dropEntityIdSearch = (

        selectedEntity === 'items' ?
        itemList !== null ?
            <DropdownMenu>
                {Object.values(itemList).filter(item => entityIdSearch.length > 0 && item.canOpen &&
                        (item.name.toLowerCase().includes(entityIdSearch.toLowerCase()) || Number(item.id).toString().includes(entityIdSearch)))
                .sort((a, b) => a.name > b.name)
                .slice(0, 10)
                .map(item => (
                <DropdownItem key={uuid()} value={item.id} onClick={(e) => selectNpcIdSearch(e, `${item.name} ${item.id}`)}>
                    {`${item.name} ${item.id}`}
                </DropdownItem>
                ))}
            </DropdownMenu>

            : <></>
        :
        npcList !== null ?
            <DropdownMenu>
                {Object.values(npcList).filter(npc => entityIdSearch.length > 0 && npc.combat === true &&
                        (npc.name.toLowerCase().includes(entityIdSearch.toLowerCase()) || Number(npc.id).toString().includes(entityIdSearch)))
                .sort((a, b) => a.name > b.name)
                .slice(0, 10)
                .map(npc => (
                <DropdownItem key={uuid()} value={npc.id} onClick={(e) => selectNpcIdSearch(e, `${npc.name} ${npc.id}`)}>
                    {`${npc.name} ${npc.id}`}
                </DropdownItem>
                ))}
            </DropdownMenu>

            : <></>
    )


    const VoucherPlayerIdSearch = (
        players !== null ?
            <DropdownMenu>
                {players.filter(player => playerIdSearch.length > 0 &&
                        (player.username.toLowerCase().includes(playerIdSearch.toLowerCase()) || Number(player.user_id).toString().includes(playerIdSearch)))
                .sort((a, b) => a.username > b.username)
                .slice(0, 10)
                .map(player => (
                <DropdownItem key={uuid()} value={player.user_id} onClick={(e) => selectPlayerIdSearch(e, `${player.username} ${player.user_id}`)}>
                    {`${player.username} | ${player.user_id}`}
                </DropdownItem>
                ))}
            </DropdownMenu>

            : <></>
    )
        // on select of an item search result in drops form
        const selectItemIdSearch = (e, result, id, searchIndex) => {
            document.getElementById(id).value = result;

            if (selectedTable === Tables.DROPS) {
                const temp = {...activeDrop};
                // guaranteed
                if (searchIndex[0] === 24) {
                    temp.guaranteed[searchIndex[1]].id = parseInt(e.target.value);
                } else {
                    temp.tables[searchIndex[0]].items[searchIndex[1]].id = parseInt(e.target.value);
                }
                setActiveDrop(temp);
            }
            if (selectedTable === Tables.SHOPS) {
                const temp = {...activeShop};
                temp.defaultStock[searchIndex[1]].id = parseInt(e.target.value);
                setActiveShop(temp);
            }
            if (selectedTable === Tables.VOUCHERS) {
                const temp = {...activeReward};
                temp.items[searchIndex[1]].id = parseInt(e.target.value);
                setActiveReward(temp);
            }
        }

        // handle input typed into the dropdown search for item id's in drops form
        const handleDropItemIdSearch = (e, index) => {
            let temp = [...itemIdSearch];
            temp[index[0]][index[1]] = e.target.value;
            setItemIdSearch(temp);
        }

        const saveDrop = () => {
            const data = {
                name: activeDrop.name,
                drop: {
                ...activeDrop,
                    name: undefined,
                    guaranteed: activeDrop.guaranteed?.filter(item => item.id !== 0).map(item => {return{...item, id: Number(item.id)}}) || [],
                    tables: activeDrop.tables.map(table => {
                        return {...table, items: table.items.filter(item => item.id !== 0).map(item => {return{...item, id: Number(item.id)}})}
                    })
                }
            }
            DashboardService.saveDrop(selectedEntity, data);
        }

        const saveShop = () => {
            const shop = {...activeShop};
            const name = activeShop.name;
            delete shop.name;
            DashboardService.saveShop(name, shop);
        } 

        const saveToProd = () => {
            const category = selectedCategory === Categories.DROPS ? 'drops_eco' : selectedCategory === Categories.SHOPS ? '' : null;
            if (category === null) {
                console.log('no category');
                return;
            }

            const path = `${selectedEntity}_${category}`;

            DashboardService.saveToProd(path);
        }
    
        const exportTable = (index) => {
            return `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(activeDrop.tables[index]))}`;
        }

        const importTable = (e) => {
            const onLoad = (event) => {
                const data = JSON.parse(event.target.result);
                const temp = {...activeDrop};

                if (data.tables !== undefined) temp.tables.push(...data.tables);
                else temp.tables.push(data);
                setActiveDrop(temp);
            }

            const reader = new FileReader();
            reader.onload = onLoad;
            reader.readAsText(e.target.files[0]);
        }

    const toggleDropSimModal = () => setDropSimOpen(current => !current);


    // the final data to display to the table
    const data = useMemo(() => {
        return activeFilter > -1 && activeFilter < headers.length && search.length > 0 ? 
        currentData.filter(d => `${d[headers[activeFilter]]}`.toLowerCase().includes(search.toLowerCase())).slice((page - 1) * 50, page * 50)
            : 
        currentData.slice((page - 1) * 50, page * 50);
    }, [currentData]
    );

    const totalTableWeight = useMemo(() => {
        return activeDrop?.tables.reduce((a, b) => +a + +b.weight, 0) || 0;
    }, [activeDrop])

    const GetChance = memo(({index}) => {
        if (index[0] === -1) {
            return (<div className='chance'>1 / 1</div>)
        } else {
        const tableChance = activeDrop.tables[index[0]].weight / totalTableWeight;
        const totalItemWeight = activeDrop.tables[index[0]].items.reduce((a, b) => +a + +b.weight, 0);
        const chance = (1 / (tableChance * (activeDrop.tables[index[0]].items[index[1]].weight / totalItemWeight)));

        return (<div className='chance'>1 / {Math.round(chance)}</div>)
        }
    });


    const setShops = () => {
        setCurrentData(Object.values(shopList).map(d => {
            const name = d.name.replace(/ /g, '_');
            return {...d, 
                data: {
                    ...shops.find(i => Object.keys(i)[0] === name)[name], 
                    name: `${name}.yaml`
                }}
        }));
        setSelectedCategory(Categories.SHOPS);
        setSearch('');
        setSelectedEntity('shops');
        setHeaders(['name']);
        document.getElementById('drops-selector').value = '';
    }

    const setRewards = () => {
        setCurrentData(Object.values(rewards).map((r) => {return {id: r.id, name: r.name, data: {...r, items: JSON.parse(r.items)}}}));
        setSelectedCategory(Categories.REWARDS);
        setSelectedEntity('vouchers');
        setHeaders(['name', 'id']);
        document.getElementById('drops-selector').value = '';
    }

    const newReward = () => {
        new Promise((resolve, reject) => DashboardService.addReward(resolve, reject))
        .then(() => {
            new Promise((resolve, reject) => DashboardService.getRewards(resolve, reject));
        });
    }

    const saveReward = () => {
        const reward = {...activeReward, players: activeReward.ids[0] === 0 ? players.filter(p=>p.user_id!==0) : players.filter(p=>activeReward.ids.includes(p.user_id))};
        DashboardService.saveReward(reward);
    } 

    const getItemName = (id) => {
        return itemList[id].name;
    }

    return (
        <div className='dashboard'>
            {selectedTable === Tables.GENERAL &&
            <div>
                {loading ? <div>Loading...</div>
                :
                <div className='dash-header' role='nav'>
                    <label htmlFor='select'>
                        Drops<br/>
                    <select id='drops-selector' onChange={(e) => selectDropType(e)}>
                        <option defaultValue={''}></option>
                        <option value='npcs'>Npcs</option>
                        <option value='items'>Items</option>
                        <option value='objects'>Objects</option>
                    </select>
                    </label>
                    <Button type='button' color='primary' id='shops-btn' onClick={() => setShops()}>Shops</Button>
                    <Button type='button' color='primary' id='vouchers-btn' onClick={() => setRewards()}>Rewards</Button>
                    {selectedCategory !== Categories.NONE && 
                        <Button type='button' color='warning' id='save-prod-btn' onClick={() => saveToProd()}>
                            Save {selectedEntity} {selectedEntity.toLowerCase() !== selectedCategory.toLowerCase() ? selectedCategory.toLowerCase() : ''} To Production
                        </Button>
                    }
                </div>
                }
                    {selectedCategory === Categories.REWARDS && 
                    <Button type='button' color='success' id='add-reward' onClick={()=>{newReward()}}>New Reward</Button>}
                <Table dark responsive hover striped>

                    <thead>
                        <tr>
                        {headers.map((head, index) => 
                        (<th key={uuid()}>{head}</th>))}
                        </tr>
                    
                        <tr>
                            
                        {headers.map((head, index) => 
                            <td><input type='text' onFocus={() => setActiveFilter(index)} onChange={(e) => handleFilter(e)} /></td>
                        )}
                        
                        </tr>
                        
                    </thead>
                    <tbody>
                    {data.map(row => 
                        <tr key={uuid()} onClick={(e) => selectGeneralRow(e, row.data)}>
                            {headers.map(head => (
                                <td key={uuid()}>{row[head]}</td>
                            ))}
                        </tr>
                    )}
                    </tbody>
                </Table>
            </div> 
            }



            {selectedTable === Tables.DROPS && !refresh &&
            <div>
                <Button onClick={() => backToGeneral()}><i className="fas fa-arrow-alt-circle-left"></i> Back</Button>
                <Button color='warning' onClick={() => saveDrop()}>Save <i className="fas fa-save"></i></Button>
                <Form className='drops-form'>
                    <InputGroup className='name'>
                    <InputGroupText className="prepend">Name</InputGroupText>
                        <Input id='name' onChange={(e) => handleDropForm(e, [-1, -1])} value={activeDrop.name}/>
                    </InputGroup>
                    <InputGroup className='ids'>
                    {activeDrop.ids.map((id, index ) => (
                        <Badge key={uuid()} color='info'>{id} <i className="fas fa-backspace fa-md ico" id='remove-id' onClick={(e => handleDropForm(e, [index, -1]))}></i></Badge>
                    ))}

                    <div className='id-input'>
                        <Dropdown direction='down' toggle={() => {}}>
                            <DropdownToggle data-toggle='dropdown' tag='span'>
                                <Input id='npc-id-search' type='text' onChange={(e) => handleDropNpcIdSearch(e)}/>
                            </DropdownToggle>
                            {dropEntityIdSearch}
                        </Dropdown>
                        <Button type='button' id='add-id' color='success' onClick={(e => handleDropForm(e))}>Add ID<i className="fas fa-plus-square"></i></Button>
                    </div>
                    </InputGroup>
                    <Button color='success' type='button' id={`add-table`} onClick={(e) => handleDropForm(e, [-1, -1])}>New Table <i className="fas fa-plus-square"></i></Button>
                    <Button color='primary' type='button' onClick={() => document.getElementById('file-import').click()}>
                        <input type='file' accept='.json' id='file-import' onChange={(e) => importTable(e)} />
                        Import Table <i className="fas fa-file-download"></i>
                    </Button>
                    <Button color='warning' type='button' onClick={(e) => toggleDropSimModal()}>Simulate Rolls<i className="fas fa-dice"></i></Button>
                    <UncontrolledAccordion stayOpen>
                    <AccordionHeader targetId={'guaranteed'}>
                                Guaranteed
                            </AccordionHeader>
                            <AccordionBody accordionId={'guaranteed'}>
                            <Button type='button' color='success' id={`add-item${-1}`} onClick={(e) => handleDropForm(e, [-1, -1])}>Add Item <i className="fas fa-plus-square"></i></Button>
                            <div className='divider'></div>
                                {activeDrop.guaranteed?.map((item, i) => (
                                    <div className='item-group'>
                                    <InputGroup>
                                    <InputGroupText className='prepend'>Item:</InputGroupText>
                                    <Dropdown direction='down' toggle={() => {}}>
                                        <DropdownToggle data-toggle='dropdown' tag='span'>
                                            <Input id={`item-id-search${24}${i}`} defaultValue={`${item.name} ${item.id}`} type='text' onChange={(e) => handleDropItemIdSearch(e, [24, i])}/>
                                        </DropdownToggle>
                                        <DropItemIdSearch 
                                            itemIdSearch={itemIdSearch} 
                                            selectItemIdSearch={selectItemIdSearch} 
                                            id={`item-id-search${24}${i}`}
                                            itemList={itemList}
                                            searchIndex={[24, i]}
                                            itemName={item.name}
                                            itemId={item.id}
                                        />
                                    </Dropdown>
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupText className='prepend'>Min:</InputGroupText>
                                        <Input value={activeDrop.guaranteed?.[i].min} id={`item-min${-1}${i}`} type='number' min={1} onChange={(e) => handleDropForm(e, [-1, i])} />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupText className='prepend'>Max:</InputGroupText>
                                        <Input value={activeDrop.guaranteed?.[i].max} id={`item-max${-1}${i}`} type='number' min={1} onChange={(e) => handleDropForm(e, [-1, i])} />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupText className='prepend'>Weight:</InputGroupText>
                                        <Input disabled={true} value={''} type='number'/>
                                    </InputGroup>
                                    <GetChance index={[-1, -1]}></GetChance>
                                    <Button type='button' color='danger' id={`remove-item${-1}${i}`} onClick={(e) => handleDropForm(e, [-1, i])}>Remove Item <i className="fas fa-minus-square"></i></Button>
                                    
                                    </div>
                                ))}
                            </AccordionBody>





                        {activeDrop.tables.map((table, index) => (
                            <>
                            <AccordionHeader targetId={`${index}`}>
                                {table.name}
                            </AccordionHeader>
                            <AccordionBody accordionId={`${index}`}>
                                <div className='item-group'>
                                <InputGroup>
                                    <InputGroupText className='prepend'>Table Name:</InputGroupText>
                                    <Input type='text' id={`table-name${index}`} value={activeDrop.tables[index].name} onChange={(e) => handleDropForm(e, [index, -1])} />
                                </InputGroup>
                                <InputGroup>
                                    <InputGroupText className='prepend'>Table Weight:</InputGroupText>
                                    <Input type='number' id={`table-weight${index}`} value={activeDrop.tables[index].weight} min={1} onChange={(e) => handleDropForm(e, [index, -1])} />
                                </InputGroup>
                                <Button type='button' color='success' id={`add-item${index}`} onClick={(e) => handleDropForm(e, [index, -1])}>Add Item <i className="fas fa-plus-square"></i></Button>
                                <Button type='button' color='danger' id={`remove-table${index}`} onClick={(e) => handleDropForm(e, [index, -1])}>Remove Table <i className="fas fa-minus-square"></i></Button>
                                <Button type='button' color='primary' onClick={() => document.getElementById('file-export').click()}><a id='file-export' href={exportTable(index)} download={`${activeDrop.tables[index].name}.json`}></a>Export Table <i className='fas fa-file-upload'></i></Button>
                                </div>
                                <div className='divider'></div>
                                {table.items.map((item, i) => (
                                    <div className='item-group'>
                                    <InputGroup>
                                    <InputGroupText className='prepend'>Item:</InputGroupText>
                                    <Dropdown direction='down' toggle={() => {}}>
                                        <DropdownToggle data-toggle='dropdown' tag='span'>
                                            <Input id={`item-id-search${index}${i}`} defaultValue={`${item.name} ${item.id}`} type='text' onChange={(e) => handleDropItemIdSearch(e, [index, i])}/>
                                        </DropdownToggle>
                                        <DropItemIdSearch 
                                            itemIdSearch={itemIdSearch} 
                                            selectItemIdSearch={selectItemIdSearch} 
                                            id={`item-id-search${index}${i}`}
                                            itemList={itemList}
                                            searchIndex={[index, i]}
                                        />
                                    </Dropdown>
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupText className='prepend'>Min:</InputGroupText>
                                        <Input value={activeDrop.tables[index].items[i].min} id={`item-min${index}${i}`} type='number' min={1} onChange={(e) => handleDropForm(e, [index, i])} />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupText className='prepend'>Max:</InputGroupText>
                                        <Input value={activeDrop.tables[index].items[i].max} id={`item-max${index}${i}`} type='number' min={1} onChange={(e) => handleDropForm(e, [index, i])} />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupText className='prepend'>Weight:</InputGroupText>
                                        <Input value={activeDrop.tables[index].items[i].weight} id={`item-weight${index}${i}`} type='number' min={1} onChange={(e) => handleDropForm(e, [index, i])} />
                                    </InputGroup>
                                    <GetChance index={[index, i]}></GetChance>
                                    <Button type='button' color='danger' id={`remove-item${index}${i}`} onClick={(e) => handleDropForm(e, [index, i])}>Remove Item <i className="fas fa-minus-square"></i></Button>
                                    </div>
                                ))}
                            </AccordionBody>
                            </>
                        ))}
                    </UncontrolledAccordion>
                </Form>
                <DropSimModal toggleDropSimModal={toggleDropSimModal} dropSimOpen={dropSimOpen} activeDrop={activeDrop} totalTableWeight={totalTableWeight}></DropSimModal>
            </div>
        }

        {selectedTable === Tables.SHOPS &&
        <div>
            <Button onClick={() => backToGeneral()}><i className="fas fa-arrow-alt-circle-left"></i> Back</Button>
            <Button color='warning' onClick={() => saveShop()}>Save <i className="fas fa-save"></i></Button>
            <Form className='drops-form'>
                <InputGroup className='name'>
                <InputGroupText className="prepend">Name</InputGroupText>
                    <Input id='name' onChange={(e) => handleShopForm(e, -1)} value={activeShop.name}/>
                </InputGroup>

                <InputGroup className='name'>
                <InputGroupText className='prepend'>Identifier</InputGroupText>
                    <Input id='identifier' onChange={(e) => handleShopForm(e, -1)} value={activeShop.identifier}/>
                </InputGroup>
                <InputGroup className='name'>
                <InputGroupText className='prepend'>Title</InputGroupText>
                    <Input id='title' onChange={(e) => handleShopForm(e, -1)} value={activeShop.title}/>
                </InputGroup>
                <InputGroup className='name'>
                <InputGroupText className='prepend'>Currency</InputGroupText>
                    <Input id='currency' onChange={(e) => handleShopForm(e, -1)} value={activeShop.currency}/>
                </InputGroup>
                <br/>
                <FormGroup switch className='name'>
                    <Input id='ironman' className='check-switch' type='switch' role='switch' onChange={(e) => handleShopForm(e, -1)} checked={activeShop.accessibleByIronMan}/>
                    <Label className='check-label'>Accessible By IronMan</Label>
                </FormGroup>
                <FormGroup switch className='name'>
                    <Input id='sell' className='check-switch' type='switch' role='switch' onChange={(e) => handleShopForm(e, -1)} checked={activeShop.canSellToStore}/>
                    <Label className='check-label'>Can Sell To</Label>
                </FormGroup>
                <FormGroup switch className='name'>
                    <Input id='general' className='check-switch' type='switch' role='switch' onChange={(e) => handleShopForm(e, -1)} checked={activeShop.generalStore}/>
                    <Label className='check-label'>Is General Store</Label>
                </FormGroup>
                <FormGroup switch className='name'>
                    <Input id='donator' className='check-switch' type='switch' role='switch' onChange={(e) => handleShopForm(e, -1)} checked={activeShop.donationShop}/>
                    <Label className='check-label'>Is Donator Shop</Label>
                </FormGroup>
                <FormGroup switch className='name'>
                    <Input id='fence' className='check-switch' type='switch' role='switch' onChange={(e) => handleShopForm(e, -1)} checked={activeShop.fence}/>
                    <Label className='check-label'>Is Fence</Label>
                </FormGroup>
                <InputGroup className='name'>
                <InputGroupText className='prepend'>Restock Ticks</InputGroupText>
                    <Input id='restocktick' type='number' onChange={(e) => handleShopForm(e, -1)} value={activeShop.restockRules.restockTicks}/>
                </InputGroup>
                <InputGroup className='name'>
                <InputGroupText className='prepend'>Per Tick</InputGroupText>
                    <Input id='restockamount' type='number' onChange={(e) => handleShopForm(e, -1)} value={activeShop.restockRules.restockPerTick}/>
                </InputGroup>

                <UncontrolledAccordion stayOpen>
                    <AccordionHeader targetId={'stock'}>
                                Default Stock
                            </AccordionHeader>
                            <AccordionBody accordionId={'stock'}>
                            <Button type='button' color='success' id='add-item' onClick={(e) => handleShopForm(e, -1)}>Add Item <i className="fas fa-plus-square"></i></Button>
                            <div className='divider'></div>
                                {activeShop.defaultStock.map((item, i) => (
                                    <div className='item-group'>
                                    <InputGroup>
                                    <InputGroupText className='prepend'>Item:</InputGroupText>
                                    <Dropdown direction='down' toggle={() => {}}>
                                        <DropdownToggle data-toggle='dropdown' tag='span'>
                                            <Input id={`item-id-search${24}${i}`} defaultValue={`${getItemName(item.id)} ${item.id}`} type='text' onChange={(e) => handleDropItemIdSearch(e, [24, i])}/>
                                        </DropdownToggle>
                                        <DropItemIdSearch 
                                            itemIdSearch={itemIdSearch} 
                                            selectItemIdSearch={selectItemIdSearch} 
                                            id={`item-id-search${24}${i}`}
                                            itemList={itemList}
                                            searchIndex={[24,i]}
                                            itemName={getItemName(item.id)}
                                            itemId={item.id}
                                        />
                                    </Dropdown>
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupText className='prepend'>Amount:</InputGroupText>
                                        <Input value={activeShop.defaultStock?.[i].amount} id={`item-amount${i}`} type='number' min={1} onChange={(e) => handleShopForm(e, i)} />
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupText className='prepend'>Price:</InputGroupText>
                                        <Input value={activeShop.defaultStock?.[i].price} id={`item-price${i}`} type='number' min={1} onChange={(e) => handleShopForm(e, i)} />
                                    </InputGroup>
                                    <Button type='button' color='danger' id={`remove-item${i}`} onClick={(e) => handleShopForm(e, i)}>Remove Item <i className="fas fa-minus-square"></i></Button>
                                    
                                    </div>
                                ))}
                            </AccordionBody>
                        </UncontrolledAccordion>
            </Form>
        </div>
        }
        {selectedTable === Tables.VOUCHERS &&
        <div>
            <Button onClick={() => backToGeneral()}><i className="fas fa-arrow-alt-circle-left"></i> Back</Button>
            <Button color='warning' onClick={() => saveReward()}>Save <i className="fas fa-save"></i></Button>
            <Form className='drops-form'>
            <InputGroup className='name'>
                <InputGroupText className="prepend">Name</InputGroupText>
                    <Input id='name' onChange={(e) => handleRewardForm(e, -1)} value={activeReward.name}/>
                </InputGroup> 

                <UncontrolledAccordion stayOpen>
                    <AccordionHeader targetId={'stock'}>
                                Items
                            </AccordionHeader>
                            <AccordionBody accordionId={'stock'}>
                            <Button type='button' color='success' id='add-item' onClick={(e) => handleRewardForm(e, -1)}>Add Item <i className="fas fa-plus-square"></i></Button>
                            <div className='divider'></div>
                                {activeReward.items.map((item, i) => (
                                    <div className='item-group'>
                                    <InputGroup>
                                    <InputGroupText className='prepend'>Item:</InputGroupText>
                                    <Dropdown direction='down' toggle={() => {}}>
                                        <DropdownToggle data-toggle='dropdown' tag='span'>
                                            <Input id={`item-id-search${24}${i}`} defaultValue={`${getItemName(item.id)} ${item.id}`} type='text' onChange={(e) => handleDropItemIdSearch(e, [24, i])}/>
                                        </DropdownToggle>
                                        <DropItemIdSearch 
                                            itemIdSearch={itemIdSearch} 
                                            selectItemIdSearch={selectItemIdSearch} 
                                            id={`item-id-search${24}${i}`}
                                            itemList={itemList}
                                            searchIndex={[24,i]}
                                            itemName={getItemName(item.id)}
                                            itemId={item.id}
                                        />
                                    </Dropdown>
                                    </InputGroup>
                                    <InputGroup>
                                        <InputGroupText className='prepend'>Amount:</InputGroupText>
                                        <Input value={activeReward.items?.[i].amount} id={`item-amount${i}`} type='number' min={1} onChange={(e) => handleRewardForm(e, i)} />
                                    </InputGroup>
                                    <Button type='button' color='danger' id={`remove-item${i}`} onClick={(e) => handleRewardForm(e, i)}>Remove Item <i className="fas fa-minus-square"></i></Button>
                                    
                                    </div>
                                ))}
                            </AccordionBody>
                        </UncontrolledAccordion>
            </Form>
            {activeReward.ids.map((id, index ) => {
                let p = players.find((p)=>p.user_id === id);
                return <Badge key={uuid()} color='info'>{`${p.username} | ${p.user_id}`} 
                            <i className="fas fa-backspace fa-md ico" id='remove-id' onClick={(e => handleRewardForm(e, [index, -1]))}></i>
                        </Badge>
            }

            )}

            <div className='id-input'>
                <Dropdown direction='down' toggle={() => {}}>
                    <DropdownToggle data-toggle='dropdown' tag='span'>
                        <Input id='player-id-search' type='text' onChange={(e) => handlePlayerIdSearch(e)}/>
                    </DropdownToggle>
                    {VoucherPlayerIdSearch}
                </Dropdown>
                <Button type='button' id='add-id' color='success' onClick={(e => handleRewardForm(e))}>Add ID<i className="fas fa-plus-square"></i></Button>
            </div>
        </div>
        }
    </div>
    )
}

export default Dashboard;
