
export const Types = {
    SET_USER: 'SET_USER',
    SET_STATUS: 'SET_STATUS',
    PLAYER_COUNT: 'PLAYER_COUNT',
    SET_NPC_DROPS: 'SET_NPC_DROPS',
    SET_ITEM_DROPS: 'SET_ITEM_DROPS',
    SET_OBJECT_DROPS: 'SET_OBJECT_DROPS',
    SET_ITEM_LIST: 'SET_ITEM_LIST',
    SET_NPC_LIST: 'SET_NPC_LIST',
    SET_OBJECT_LIST: 'SET_OBJECT_LIST',
    SET_SHOP_LIST: 'SET_SHOP_LIST',
    SET_SHOPS: 'SET_SHOPS',
    ADD_ALERT: 'ADD_ALERT',
    REMOVE_ALERT: 'REMOVE_ALERT',
    SET_PLAYERS: 'SET_PLAYERS',
    SET_REWARDS: 'SET_REWARDS',
 }


export const setUser = user => {
    return { type: Types.SET_USER, payload: user }
}

export const setStatus = status => {
    return { type: Types.SET_STATUS, payload: status }
}

export const setPlayerCount = count => {
    return { type: Types.PLAYER_COUNT, payload: count }
}

export const setNpcDrops = drops => {
    return {type: Types.SET_NPC_DROPS, payload: drops}
}

export const setItemDrops = drops => {
    return {type: Types.SET_ITEM_DROPS, payload: drops}
}

export const setObjectDrops = drops => {
    return {type: Types.SET_OBJECT_DROPS, payload: drops}
}

export const setItemList = list => {
    return {type: Types.SET_ITEM_LIST, payload: list}
}

export const setNpcList = list => {
    return {type: Types.SET_NPC_LIST, payload: list}
}

export const setObjectList = list => {
    return {type: Types.SET_OBJECT_LIST, payload: list}
}

export const setShopList = list => {
    return {type: Types.SET_SHOP_LIST, payload: list}
}

export const setShops = shops => {
    return {type: Types.SET_SHOPS, payload: shops}
}

export const addAlert = alert => {
    return {type: Types.ADD_ALERT, payload: alert}
}

export const removeAlert = alerts => {
    return {type: Types.REMOVE_ALERT, payload: alerts}
}

export const setPlayers = players => {
    return {type: Types.SET_PLAYERS, payload: players}
}

export const setRewards = rewards => {
    return {type: Types.SET_REWARDS, payload: rewards}
}
