import React, { useState } from 'react'

import { useSelector } from 'react-redux';
import { selectStatus } from '@state/selectors';
import { selectUser } from '@state/selectors';





const GamblePack = () => {

    const cart = require('@resources/cart.png');
    const dcart = require('@resources/cart-disabled.png');

    const isAuth = useSelector(selectStatus);
    const user = useSelector(selectUser);


    const saleId = '';

    const bondText = 'Redeem this to add towards your donator rank and spend the credits in the donator shop!';
    const goldText = 'Shiny Gold!';
    const mysteryText = 'A mystery box that could land you a valuable new item, or more gold!';

    const packages = [
        [
            {qty: '1X •', item: '$10 Bond:', description:  bondText},
            {qty: '1X •', item: '50M Gold:', description: goldText},
            {qty: '1X •', item: 'Mystery Box:', description: mysteryText}
        ],
        [
            {qty: '2X •', item: '$10 Bond:', description:  bondText},
            {qty: '1X •', item: '75M Gold:', description: goldText},
            {qty: '2X •', item: 'Mystery Box:', description: mysteryText}
        ],
        [
            {qty: '4X •', item: '$10 Bond:', description:  bondText},
            {qty: '1X •', item: '150M Gold:', description: goldText},
            {qty: '3X •', item: 'Mystery Box:', description: mysteryText}
        ]
    ];

    const [pack, setPack] = useState(packages[0]);
    const [watchList] = useState(document.getElementsByName('watch'));

    const config = {  attributes: true, childList: true, characterData: true }
    
    const callback = function(mutationsList, observer) {
        for (let m of mutationsList) {
            if (m.attributeName === 'value') {
                m.target.classList.add('flash');
                setTimeout(() => {
                    m.target.classList.remove('flash');
                }, 3000);

            }
        }
    }
    const observer = new MutationObserver(callback);



    for (let el of watchList) {
        observer.observe(el, config);
    }


    
    return (
        <div className='store-item-paypal'>


        <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value="PNME3F6A9DAAE" />
            <input type="hidden" name="on1" value={user?.username} />
            <input type="hidden" name="on2" value={saleId} />
            <table>
                <thead>
                    <tr><td><input type="hidden" name="on0" value="Package" />Package</td></tr>
                </thead>
                <tbody>
                    <tr><td>
                    <select name="os0" onChange={(e) => setPack(packages[e.target.selectedIndex])}>
                        <option value="Novice">Novice • $20.00 USD</option>
                        <option value="Enthusiast">Enthusiast • $35.00 USD</option>
                        <option value="High Roller">High Roller • $50.00 USD</option>
                    </select>
                    </td></tr>
                </tbody>
            </table>
            <input type="hidden" name="currency_code" value="USD" />
            <br/>
            <input className={isAuth ? '' : 'd-cursor'} disabled={!isAuth} type="image" src={isAuth ? cart : dcart} border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
            <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"></img>
        </form>

        <div>
            <img draggable="false" alt="items in selected package" className='item-img' src={require('@resources/gamble-pack.png')} ></img>

            <p><span className='qty' name="watch" value={pack[0].qty}>{pack[0].qty}</span> <span className='item-name' name="watch" value={pack[0].item}>{pack[0].item}</span> <span className='desc'>{pack[0].description}</span></p>
            <p><span className='qty' name="watch" value={pack[1].qty}>{pack[1].qty}</span> <span className='item-name' name="watch" value={pack[1].item}>{pack[1].item}</span> <span className='desc'>{pack[1].description}</span></p>
            <p><span className='qty' name="watch" value={pack[2].qty}>{pack[2].qty}</span> <span className='item-name' name="watch" value={pack[2].item}>{pack[2].item}</span> <span className='desc'>{pack[2].description}</span></p>
        </div>
        </div>
    )
}

export default GamblePack;
