import React from 'react'
import { Link } from 'react-router-dom';

const Sidebar = ({user}) => {

    return (
        <nav className="sidebar">
            <ul className="navbar-nav">
                <li className="nav-item">
                    <Link className="nav-link" to='/'><i className="prev store fas fa-home fa-lg"></i><p className="link-text">Home<i className="fas fa-home fa-lg"></i></p></Link>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href='https://forums.valhallars.com' target='_blank' rel="noopener noreferrer"><i className="prev fas fa-comments fa-lg"></i><p className="link-text">Forum<i className="far fa-comments fa-lg"></i></p></a>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to='/store'><i className="prev store fas fa-shopping-cart fa-lg"></i><p className="link-text">Store<i className="fas fa-shopping-cart fa-lg"></i></p></Link>
                </li>
                <li className="nav-item">
                    <Link className="nav-link" to='/vote'><i className="prev fas fa-vote-yea fa-lg"></i><p className="link-text">Vote<i className="fas fa-vote-yea fa-lg"></i></p></Link>
                </li>
                {user?.is_admin && <li className="nav-item">
                    <Link className="nav-link" to='/dashboard'><i className="prev store fas fa-toolbox fa-lg"></i><p className="link-text">Dash<i className="fas fa-toolbox fa-lg"></i></p></Link>
                </li>}

            </ul>


        </nav>
    )
}

export default Sidebar;
