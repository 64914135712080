import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectAlerts } from '@state/selectors';
import { Alert } from 'reactstrap';
import AlertsController from '../../controllers/AlertsController';

const AlertComponenet = ({alert, alerts}) => {

    const [visible, setVisible] = useState(true);


    const removeAlert = () => {
        setVisible(false);
        AlertsController.removeAlert(alert, alerts);
    }

    useEffect(() => {
        setTimeout(() => {
            removeAlert();
        }, 3000);
    }, []);


  return (
    <Alert color={alert.alertType}
            id={alert.id}
            isOpen={visible}
            >
        { alert.text }
    </Alert>
  )
}

export default AlertComponenet;