import React, { useState, useMemo } from 'react';
import {v4 as uuid} from 'uuid';
import { Button, Input, InputGroup, InputGroupText, Modal, ModalHeader, ModalBody } from 'reactstrap';

const DropSimModal = ({dropSimOpen, toggleDropSimModal, activeDrop, totalTableWeight}) => {


    const [rolls, setRolls] = useState(1);
    const [multiplier, setMultiplier] = useState(1);
    const [drops, setDrops] = useState(new Map());
    const [triggerMemo, setTriggerMemo] = useState(true);


    const dropMemo = useMemo(() => {
        console.log('memo tiggered')
        return [...drops.values()].sort((a, b) => a.chance < b.chance ? 1 : -1);
    }, [triggerMemo]);

    const shuffleArray = (arr) => {
        for (let i = arr.length -1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            const temp = arr[i];
            arr[i] = arr[j];
            arr[j] = temp;
        }
    }

    const simulate = () => {
        drops.clear();
        const tablesCopy = [...activeDrop.tables];

        for (let i = 0; i < rolls*multiplier; i++) {
            shuffleArray(tablesCopy);
            let tableRand = Math.random() * totalTableWeight;
            for (const table of tablesCopy) {
                if ((tableRand -= table.weight) <= 0) {
                    const tableChance = table.weight / totalTableWeight;
                    const totalItemWeight = table.items.reduce((a, b) => +a + +b.weight, 0);
                    let itemRand = Math.random() * totalItemWeight;

                    if (table.name === 'empty') {
                        const existing = drops.get(-1);
                        const newVal = existing ? {...existing, rolls: existing.rolls + 1} : {id: -1, amt: 0, rolls: 1, name: 'None', chance: 0};
                        setDrops(drops.set(-1, newVal));
                        break;
                    }

                    for (const item of table.items) {
                        if ((itemRand -= item.weight) <= 0) {
                            const itemChance = Math.round((1 / (tableChance * item.weight / totalItemWeight)));
                            const amt = Math.floor(Math.random() * (item.max - item.min + 1)) + item.min;
                            const existing = drops.get(item.id);
                            const newVal = existing ? 
                                {...existing, amt: existing.amt + amt, rolls: existing.rolls + 1} 
                                : 
                                {id: item.id, amt, rolls: 1, name: item.name, chance: itemChance};
                            setDrops(drops.set(item.id, newVal));
                            break;
                        }
                    }
                    break;
                }
            }
        }
        setTriggerMemo(current => !current);
    }

  return (
    <Modal isOpen={dropSimOpen} toggle={toggleDropSimModal} className="db-modal">
    <ModalHeader toggle={toggleDropSimModal}>{activeDrop?.name}</ModalHeader>
    <ModalBody>
        <InputGroup>
            <InputGroupText className='prepend'>Number of Kills/Opens:</InputGroupText>
            <Input type='number' min={1} value={rolls} onChange={(e) => setRolls(Math.max(Number(e.target.value)), 0)}></Input>
        </InputGroup>
        <InputGroup>
            <InputGroupText className='prepend'>Rolls per Kill/Open:</InputGroupText>
            <Input type='number' min={1} value={multiplier} onChange={(e) => setMultiplier(Math.max(Number(e.target.value)), 0)}></Input>
        </InputGroup>

        <Button type='button' color='warning' onClick={() => simulate()}>Simulate</Button>


        {dropMemo.map((drop, i) => (
            <div key={uuid()} className={ i % 2 === 0 ? 'drop-result mdr-dark' : 'drop-result mdr-light'}>
                <p className='n'>Name: {drop.name}</p>
                <p className='q'>QTY: {drop.amt}</p>
                <p className='r'>Rolls: {drop.rolls}</p>
                <p className='c'>Chance: 1 / {drop.chance}</p>
                {/* <p className='i'>Actual: 1 / {Math.round(((rolls * multiplier) / drop.rolls) / multiplier)}</p> */}
                <p className='i'>ID: {drop.id}</p>

            </div>
        ))}
    </ModalBody>
    {/* <ModalFooter>
    </ModalFooter> */}
  </Modal>
  )
}

export default DropSimModal;