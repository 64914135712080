// Core
import React, { useState } from 'react';
import { InputGroup, Input, InputGroupAddon, InputGroupText, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';

// Model & Helpers
import AuthHelper from '@helpers/AuthHelper.js';

const LoginModal = ({toggle, modal, setModal}) => {

    const [username, setUsername] = useState(undefined);
    const [password, setPassword] = useState(undefined);
    const [loginMessage, setLoginMessage] = useState({msg: null, status: null});

    const handleU = (e) => { 
        setUsername(e.target.value);
      }
      const handleP = (e) => {
          setPassword(e.target.value);
      }
      const attemptLogin = (e) => {
        e.preventDefault();
        AuthHelper.attemptLogin(username, password, setUsername, setPassword, setLoginMessage, setModal);
      }

      const modalBody = (        
      <>
        <ModalHeader toggle={toggle} close={<button className='btn btn-danger btn-sm' onClick={toggle}>X</button>}>Login</ModalHeader>
        <form id='loginForm' onSubmit={attemptLogin}>
        <ModalBody>
        <InputGroup>
            <InputGroupText className="prepend">User:</InputGroupText>
            <Input onChange={handleU}/>
        </InputGroup>
        <InputGroup>
                <InputGroupText className="prepend">Pass:</InputGroupText>
            <Input type='password' onChange={handleP}/>
        </InputGroup>
        </ModalBody>
        <Button className='login-btn' type="submit" color="success" disabled={!username || !password} onClick={attemptLogin}>Login</Button>
        </form>
        </>);

    return (
        <Modal isOpen={modal} toggle={toggle} className='' >
        {loginMessage.status === null ? modalBody : loginMessage.status === false ? modalBody : <></>}
            {loginMessage.status !== null &&
            <p className={loginMessage.status === true ? 'msg-green' : loginMessage.status === false ? 'msg-red' : ''}>{loginMessage.msg ? loginMessage.msg : ''}</p>
            }
        </Modal>
    )
}

export default LoginModal
