import React, { useState } from 'react'

import { useSelector } from 'react-redux';
import { selectStatus } from '@state/selectors';
import { selectUser } from '@state/selectors';

const Bonds = () => {
    const cart = require('@resources/cart.png');
    const dcart = require('@resources/cart-disabled.png');

    const bonds = [
        require('@resources/bond-sapphire.png'), 
        require('@resources/bond-emerald.png'),
        require('@resources/bond-ruby.png'),
        require('@resources/bond-diamond.png'),
        require('@resources/bond-dragonstone.png'),
        require('@resources/bond-onyx.png'),
        require('@resources/bond-zenyte.png')
    ];

    const bondText = 'Redeem this to add towards your donator rank and spend the credits in the donator shop!';

    const packages = [
            {qty: '1X •', item: '$5 Bond:', description: bondText},
               
            {qty: '1X •', item: '$10 Bond:', description: bondText},
               
            {qty: '1X •', item: '$25 Bond:', description: bondText},
           
            {qty: '1X •', item: '$50 Bond:', description: bondText},
               
            {qty: '1X •', item: '$100 Bond (+$5 Free):', description: bondText},
        
            {qty: '1X •', item: '$250 Bond (+$25 Free):', description: bondText},
                
            {qty: '1X •', item: '$500 Bond (+$100 Free):', description: bondText},
        
    ];

    const [pack, setPack] = useState(packages[0]);
    const [bond, setBond] = useState(bonds[0]);


    const updateBondAmount = (e) => {
        setBond(bonds[e.target.selectedIndex]);
        setPack(packages[e.target.selectedIndex]);
    }

    const isAuth = useSelector(selectStatus);
    const user = useSelector(selectUser);


const saleId = '';

    return (
        <div className='store-item-paypal'>

        <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
            <input type="hidden" name="cmd" value="_s-xclick" />
            <input type="hidden" name="hosted_button_id" value="6J3Z4L3G79GMS" />
            <input type="hidden" name="on1" value={user?.username} />
            <input type="hidden" name="on2" value={saleId} />
            <table>
                <thead>
                    <tr><td><input type="hidden" name="on0" value="Amount"/>Amount</td></tr>
                </thead>
                <tbody>
                    <tr><td>
                        <select name="os0" onChange={updateBondAmount}>
                            <option value="$5 Bond">Bond • $5.00 USD</option>
                            <option value="$10 Bond">Bond • $10.00 USD</option>
                            <option value="$25 Bond">Bond • $25.00 USD</option>
                            <option value="$50 Bond">Bond • $50.00 USD</option>
                            <option value="$100 Bond">Bond • $100.00 USD{'('}+5% FREE{')'}</option>
                            <option value="$250 Bond">Bond • $250.00 USD{'('}+10% FREE{')'}</option>
                            <option value="$500 Bond">Bond • $500.00 USD{'('}+20% FREE{')'}</option>
                        </select>
                    </td></tr>
                </tbody>
            </table>
            <input type="hidden" name="currency_code" value="USD" />
            <br/>
            <input className={isAuth ? '' : 'd-cursor'} disabled={!isAuth} type="image" src={isAuth ? cart : dcart} border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
            <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
        </form>

        <div>
            <img draggable="false" alt="bond that is currently selected" className='item-img' src={bond}></img>

            <p><span className='qty'>{pack.qty}</span> <span className='item-name'>{pack.item}</span> <span className='desc'>{pack.description}</span></p>
        </div>

{/* <button onClick={test}>Test paypal token</button> */}
        </div>
    )
}

export default Bonds;
