// Core
import React from 'react';
import { useSelector } from 'react-redux';

// Model & Helpers
import { selectStatus, selectUser, selectAlerts } from '@state/selectors';
import StoreService from '@services/store';

// Components
import AlertComponenet from '@components/AlertComp';

const Header = ({toggle, userProfile}) => {
    
    const logo = require('@resources/logo.png');
    
    const isAuth = useSelector(selectStatus);
    const user = useSelector(selectUser);
    const alerts = useSelector(selectAlerts);

    const signup = () => {
        window.open('https://forums.valhallars.com/register', '_blank').focus();
    }

    const logout = () => {
        StoreService.logout();
    }
    return (
        <div className='header'>
            {/* {alerts.map(alert => (
                <AlertComponenet key={alert.id} id={alert.id} alert={alert} alerts={alerts}/>
            ))} */}
            <div className='header-back'>
                {isAuth && <p><span className='greeting'>Welcome Back,</span> <span className='user' onClick={userProfile}>{user?.username}</span>
                <span className='logout' onClick={logout}>Logout</span></p>}
                {!isAuth && <p className='login-mobile'><span className='login' onClick={() => {toggle()}}>Login</span> | <span className='create' onClick={signup}>Register</span></p>}
                <img draggable="false" className='logo-img' src={logo} alt='logo'/>

            </div>
        </div>
    )
}

export default Header;