import Axios from 'axios';
import { AxiosAuth } from '../../helpers/AxiosAuth';
import LoginController from '@controllers/loginController';

class StoreService {


    login(username, password, resolve, reject) {

        Axios.post('https://forums.valhallars.com:4200/store/auth', {username, password})
        .then(response => {
            if (response.data.errors) {
                resolve({msg: response.data.errors[0].message, status: false});
            } else {
                LoginController.setUser(response.data);
                LoginController.setStatus(true);
                window.localStorage.setItem('VRSUser', response.data);
                resolve({msg: 'Login Successful!', status: true});

            }
        }).catch(err => console.log(err));
    }

    logout() {
        LoginController.setUser(null);
        LoginController.setStatus(false);
        window.localStorage.removeItem('VRSUser');
    }

    validateVoucher(voucher, resolve, reject) {
        AxiosAuth().post('https://forums.valhallars.com:4200/store/validateVoucher', {voucher})
        .then(response => {
            resolve(response.data);
        })
        .catch((err) => {
            reject(err.response.status);
        });
    }

    redeemVoucher(voucher, resolve, reject) {
        AxiosAuth().post('https://forums.valhallars.com:4200/store/redeemVoucher', {voucher})
        .then(response => {
            resolve(response.data);
        })
        .catch(err => console.log(err));
    }

}

export default new StoreService();