import React, { useEffect, useState, useMemo } from 'react';
import { DropdownMenu, DropdownItem } from 'reactstrap';
import {v4 as uuid} from 'uuid';

const DropItemIdSearch = ({itemList, itemIdSearch, selectItemIdSearch, id, searchIndex, itemName, itemId}) => {

    const [updateSearch, setUpdateSearch] = useState(true);

    // Only update search 1 second after last char typed
    useEffect(() => {
        const timeoutId = setTimeout(() => {
            setUpdateSearch(update => !update);
        }, 500);
        return () => {
            clearTimeout(timeoutId);
        }
    }, [itemIdSearch[searchIndex[0]][searchIndex[1]]]);


    const searchResult = useMemo(() => {
        return itemIdSearch[searchIndex[0]][searchIndex[1]]?.length > 0 ?
        Object.values(itemList)
            .filter(item => {
                return itemIdSearch[searchIndex[0]][searchIndex[1]].match(/^[0-9]*$/) ? // contains only numbers, search exlusively by ID
                    item.id.toString().includes(itemIdSearch[searchIndex[0]][searchIndex[1]])
                :
                itemIdSearch[searchIndex[0]][searchIndex[1]].match(/^[a-z ()+]*$/i) ? // contains no numbers, search exlusively by NAME
                    item.name.toLowerCase().includes(itemIdSearch[searchIndex[0]][searchIndex[1]].toLowerCase())
                : // generic combined search
                    `${item.name} ${item.id}`.toLowerCase().includes(itemIdSearch[searchIndex[0]][searchIndex[1]].toLowerCase())
            }).sort((a, b) => a.id < b.id ? -1 : 1).slice(0, 20) // always sort in order of lowest id to highest.
            
             : []
    }, [updateSearch]);

  return (
        <DropdownMenu dark={true}>
            {searchResult
                .map(item => (
                    <DropdownItem key={uuid()} value={Number.parseInt(item.id)} onClick={(e) => selectItemIdSearch(e, `${item.name} ${item.id}`, id, searchIndex)}>
                        {`${item.name} ${item.id}`}
                    </DropdownItem>
                    )
                )
            }
        </DropdownMenu>
        

  )
}

export default DropItemIdSearch;