import store from '@root/store';
import * as Action from '@state/actions';


class LoginController {
    setUser(user) {
        store.dispatch(Action.setUser(user));
    }
    
    setStatus(status) {
        store.dispatch(Action.setStatus(status));
    }
}

export default new LoginController();