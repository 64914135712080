import React from 'react'
import  * as jwt  from 'jsonwebtoken';


import { useSelector } from 'react-redux';
import { selectStatus } from '@state/selectors';
import { selectUser } from '@state/selectors';



const cart = require('@resources/cart.png');
const dcart = require('@resources/cart-disabled.png');
const img = require('@resources/vip.png');

const packages = [
    [
        {qty: '', item: 'Double Daily', description: 'Double Loot from the Daily Rewards box'},
        {qty: '', item: 'Double Votes', description: 'Double Vote tickets when claiming votes'},
        {qty: '', item: 'Double Well of Goodwill', description: 'Active bonuses from the Well of Goodwill are doubled for you'},
        {qty: '', item: 'Drop Rate', description: 'A flat 5% Drop rate bonus, stacks with all other drop rate bonuses'},
    ]
];

const VIP = () => {

    const isAuth = useSelector(selectStatus);
    const user = useSelector(selectUser);

    const saleId = '';

    return (
        <div className="store-item-paypal">
            <form target="paypal" action="https://www.paypal.com/cgi-bin/webscr" method="post">
                <input type="hidden" name="cmd" value="_s-xclick" />
                <input type="hidden" name="hosted_button_id" value="KLBBHMP4R9YDE" />
                <input type="hidden" name="on1" value={user?.username} />
                <input type="hidden" name="on2" value={saleId} />
            <table>
            <thead>
                <tr><td><input type="hidden" name="on0" value="Duration" />Duration</td></tr>
            </thead>
                <tbody>
                    <tr><td>
                    <select name="os0">
                        <option value="1 Month">1 Month - $12.00 USD</option>
                        <option value="6 Months">6 Months - $60.00 USD</option>
                        <option value="12 Months">12 Months - $96.00 USD</option>
                    </select>
                    </td></tr>
                </tbody>
            </table>
            <input type="hidden" name="currency_code" value="USD" />
            <br/>
            <input className={isAuth ? '' : 'd-cursor'} disabled={!isAuth} type="image" src={isAuth ? cart : dcart} border="0" name="submit" alt="PayPal - The safer, easier way to pay online!" />
            <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1" />
            </form>

            <div>
            <img draggable="false" alt="VIP subscription logo" className='item-img-wide' src={img} />
            {packages[0].map((item, index) => (
                <p key={index}><span className='qty' name="watch" value={item.qty}>{item.qty}</span> <span className='item-name' name="watch" value={item.item}>{item.item}</span> <span className='desc'>{item.description}</span></p>
            ))}

            </div>
        </div>
    )
}

export default VIP;





