import store from '@root/store';
import * as Action from '@state/actions';

class AlertController {


    newAlert = {
        id: '',
        text: '',
        alertType: ''
    }

    // add new alert
    addAlert(alert) {
        alert.id = this.generateId();
        store.dispatch(Action.addAlert(alert));
    }

    // remove existing alert by ID
    removeAlert(alert, alerts) {
        const newAlerts = alerts.filter(a => a.id !== alert.id);
        store.dispatch(Action.removeAlert(newAlerts));
    }

    // generate unique-enough ID
    generateId() {
        const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';
        let id = '';
        for (let i = 0; i < 7; i++) {
            id += chars[Math.floor(Math.random() * chars.length - 1)];
        }
        return id;
    }

}

export default new AlertController();