import * as Action from '@state/actions';

const initialState = {
    user: null,
    authStatus: false,
    onlinePlayers: undefined,
    npcDrops: null,
    itemDrops: null,
    objectDrops: null,
    itemList: null,
    npcList: null,
    objectList: null,
    shopList: null,
    shops: null,
    alerts: [],
    players: null,
    rewards: null
 };


export const reducer = (state=initialState, action) => {
    switch(action.type) {

        case Action.Types.SET_USER:
            return {...state, user: action.payload}
            
        case Action.Types.SET_STATUS:
            return {...state, authStatus: action.payload}
        case Action.Types.PLAYER_COUNT:
            return {...state, onlinePlayers: action.payload}
        case Action.Types.SET_NPC_DROPS:
            return {...state, npcDrops: action.payload}
        case Action.Types.SET_ITEM_DROPS:
            return {...state, itemDrops: action.payload}
        case Action.Types.SET_OBJECT_DROPS:
            return {...state, objectDrops: action.payload}
        case Action.Types.SET_ITEM_LIST:
            return {...state, itemList: action.payload}
        case Action.Types.SET_NPC_LIST:
            return {...state, npcList: action.payload}
        case Action.Types.SET_OBJECT_LIST:
            return {...state, objectList: action.payload}
        case Action.Types.SET_SHOP_LIST:
            return {...state, shopList: action.payload}
        case Action.Types.SET_SHOPS:
            return {...state, shops: action.payload}
        case Action.Types.ADD_ALERT:
            return {...state, alerts: [...state.alerts, action.payload]}
        case Action.Types.REMOVE_ALERT:
            return {...state, alerts: action.payload}
        case Action.Types.SET_PLAYERS:
            return {...state, players: action.payload}
        case Action.Types.SET_REWARDS:
            return {...state, rewards: action.payload}
        default:
            return {...state}
        }

}