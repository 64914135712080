import LoginController from '@controllers/loginController';
import StoreService from '@services/store';

class AuthHelper {

    checkAuth() {
        const user = window.localStorage.getItem('VRSUser');
        if (user) {
            LoginController.setUser(user);
            LoginController.setStatus(true);
        } else {
            LoginController.setUser(null);
            LoginController.setStatus(false);
        }
    }

    attemptLogin = async (username, password, setUsername, setPassword, setLoginMessage, setModal) => {
        await new Promise(function(resolve, reject) {
            StoreService.login(username, password, resolve, reject);
        }).then((res) => {
            setUsername(undefined);
            setPassword(undefined);
            document.getElementById('loginForm').reset();
            setLoginMessage(res);
            if (res.status === true)
            setTimeout(() => {
                setModal(false);
                setLoginMessage({msg: null, status: null});
            }, 3000);
        }).catch(err => {
            console.log(err);
        });
    }

}

export default new AuthHelper();