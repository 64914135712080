// Cote
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

// Model & Helpers
import { selectUser } from '@state/selectors';
import Utils from '@helpers/Utils';

const Vote = ({toggle}) => {

    const user = useSelector(selectUser);


    useEffect(() => {
        Utils.refreshVoteTimer();
    }, []);

    return (
        <div className="vote">
        {user != null && 
            <>
            <h1>Click on the links below to vote:</h1>
            {user.lastVote !== undefined &&
                ((user.lastVote >= 12 || user.lastVote == null) ? <p>You are eligible to vote!</p>
                : <><p>You can vote again in {11 - Math.floor(user.lastVote)} Hours and {60 - (Math.floor((Math.abs(Math.floor(user.lastVote) - user.lastVote)) * 60))} Minutes</p> <button onClick={() => Utils.refreshVoteTimer()}>Refresh</button></>)
            }
            <div>
                <a href={`https://topg.org/runescape-private-servers/server-635449-${user.username.replaceAll(' ', '-')}`} target="_blank" rel="noopener noreferrer"><img src="https://topg.org/topg.gif" width="100" height="87" border="0" alt="Valhalla RSPS - Runescape private server" /></a>
                <a href={`https://runelist.io/toplist/server/124/vote/${user.username.replaceAll(' ', '-')}`} target="_blank" rel="noopener noreferrer"><img src="https://runelist.io/uploads/monthly_2021_01/android-chrome-512x512.png" width="100" height="87" border="0" alt="Valhalla RSPS - Runescape private server" /></a>
                <a href={`https://www.arena-top100.com/index.php?a=in&u=valhalla&id=${user.username.replaceAll(' ', '-')}`} target="_blank" rel="noopener noreferrer"><img src="https://www.arena-top100.com/skins/arena-top100/images/logo.webp.pagespeed.ce.S83tPoqetV.webp" width="100" height="87" border="0" alt="Valhalla RSPS - Runescape private server" /></a>
            </div>
            </>
        }

        {user === null &&
        <h1>Please <span className="login" onClick={toggle}>login</span> to vote</h1>
        }
        </div>
    )
}

export default Vote;
